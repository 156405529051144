@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap");

:root {
	/* Colors */
	--blackpure: #000;
	--black: #171718;
	--black-border: #26292d;
	--white: #fff;
	--purple: #5c62ec;

	/* Nav */
	--nav-bg: var(--black);
	--nav-border: var(--black-border);
	--nav-text: var(--white);

	/* Header */
	--header-bg: var(--black);
	--header-text: var(--white);

	/* Footer */
	--footer-bg: var(--black);
	--footer-text: var(--white);

	/* Light mode */
	--page-bg: var(--white);
	--text-color: var(--black);
	--accent: var(--purple);
	--title-1: var(--accent);

	--project-card-bg: var(--white);
	--project-card-text: var(--black);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

.dark {
	/* Dark mode */
	--page-bg: #252526;
	--text-color: var(--white);

	--title-1: var(--white);

	--project-card-bg: var(--black);
	--project-card-text: var(--white);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
}



html,
body {
	min-height: 100vh;

	font-family: "DM Sans", sans-serif;
	letter-spacing: -0.5px;

	/* Light mode */
	background-color: var(--page-bg);
	color: var(--text-color);
    
}

#root {
	min-height: 100vh;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
/* General */

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}

.none {
	display: none !important;
}

.section {
	padding: 70px 0;
}

.title-1 {
	margin-bottom: 60px;

	font-size: 60px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--title-1);
	text-align: center;
}

.title-2 {
	margin-bottom: 20px;

	font-size: 40px;
	font-weight: 700;
	line-height: 1.3;
}

/* Nav */

.nav {
	z-index: 2;
	padding: 20px 0;
	background-color: rgb(245, 245, 245);
	border-bottom: 1px solid var(--nav-border);
	color: var(--nav-text);
	letter-spacing: normal;
    text-decoration: none 
}

.nav-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	column-gap: 30px;
	row-gap: 20px;
	flex-wrap: wrap;
}

.logo {
	margin-right: auto;
	color: black;
	font-size: 24px;
	font-family: "Poppins", sans-serif;
}

.logo strong {
	font-weight: 700;
}

.nav-list {
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;
	align-items: center;
	column-gap: 40px;
	font-size: 16px;
	font-weight: 500;
	font-family: "Poppins", sans-serif;
}

.nav-list__link {
	color: black;
	transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
	opacity: 0.8;
}


.nav-list__link--active {
    position: relative;
}


.nav-list__link--active::before {
    content: "";
    position: absolute;
    left: 50%; 
    bottom: 0;
    transform: translateX(-50%); 
    display: block;
    height: 2px;
    width: 100%; 
    background-color: var(--accent);
    transition: width 0.3s ease-in-out; 
}


.nav-list__link--active:hover::before {
    width: 0; 
}
