@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap");

:root {
	/* Colors */
	--blackpure: #000;
	--black: #171718;
	--black-border: #26292d;
	--white: #fff;
	--purple: #5c62ec;

	/* Nav */
	--nav-bg: var(--black);
	--nav-border: var(--black-border);
	--nav-text: var(--white);

	/* Header */
	--header-bg: var(--black);
	--header-text: var(--white);

	/* Footer */
	--footer-bg: var(--black);
	--footer-text: var(--white);

	/* Light mode */
	--page-bg: var(--white);
	--text-color: var(--black);
	--accent: var(--purple);
	--title-1: var(--accent);

	--project-card-bg: var(--white);
	--project-card-text: var(--black);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

.dark {
	/* Dark mode */
	--page-bg: #252526;
	--text-color: var(--white);

	--title-1: var(--white);

	--project-card-bg: var(--black);
	--project-card-text: var(--white);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
}

html,
body {
	min-height: 100vh;

	font-family: "DM Sans", sans-serif;
	letter-spacing: -0.5px;

	/* Light mode */
	background-color: var(--page-bg);
	color: var(--text-color);
}

#root {
	min-height: 100vh;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
/* General */

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}

.none {
	display: none !important;
}

.section {
	padding: 70px 0;
}

.title-1 {
	margin-bottom: 60px;

	font-size: 60px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--title-1);
	text-align: center;
}

.title-2 {
	margin-bottom: 20px;

	font-size: 40px;
	font-weight: 700;
	line-height: 1.3;
}


.header {
	padding: 40px 0;
	min-height: 695px;
	position: relative;
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: white;
	/* background-image: url('./header-bg.png'); */
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center center;

	color: black;
	text-align: center;
}

.header__wrapper {
	position: relative;
	z-index: 1;
	padding: 0 15px;
	max-width: 660px;
}

.header__title {
	margin-bottom: 20px;
	font-size: 35px;
	font-weight: 540;
	line-height: 1.4;
}

.header__title strong {
	font-size: 60px;
	font-weight: 700;
}

.header__title em {
	font-style: normal;
	color: var(--accent);
}

.header__text {
	margin-bottom: 40px;
	font-size: 18px;
	line-height: 1.333;
	margin-right: 5px;
}

.header__text p + p {
	margin-top: 0.5em;
}

/* Btn */

.btn {
	display: inline-block;
	height: 30px;
	padding: 12px 28px;
    margin-top: 10px;
	border-radius: 5px;
	background-color: var(--accent);
	color: var(--white);
	letter-spacing: 0.15px;
	font-size: 16px;
	font-weight: 500;
	margin-right: auto;
	transition: opacity 0.2s ease-in;
}

.btn:hover {
	opacity: 0.8;
}

.btn:active {
	position: relative;
	top: 1px;
}

#particles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1; /* Это установит particles под header */
  }