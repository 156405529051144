@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap");

:root {
	/* Colors */
	--blackpure: #000;
	--black: #171718;
	--black-border: #26292d;
	--white: #fff;
	--purple: #5c62ec;

	/* Nav */
	--nav-bg: var(--black);
	--nav-border: var(--black-border);
	--nav-text: var(--white);

	/* Header */
	--header-bg: var(--black);
	--header-text: var(--white);

	/* Footer */
	--footer-bg: var(--black);
	--footer-text: var(--white);

	/* Light mode */
	--page-bg: var(--white);
	--text-color: var(--black);
	--accent: var(--purple);
	--title-1: var(--accent);

	--project-card-bg: var(--white);
	--project-card-text: var(--black);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

.dark {
	/* Dark mode */
	--page-bg: #252526;
	--text-color: var(--white);

	--title-1: var(--white);

	--project-card-bg: var(--black);
	--project-card-text: var(--white);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
}

html,
body {
	min-height: 100vh;

	font-family: "DM Sans", sans-serif;
	letter-spacing: -0.5px;

	/* Light mode */
	background-color: var(--page-bg);
	color: var(--text-color);
}

#root {
	min-height: 100vh;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
/* General */

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}

.none {
	display: none !important;
}

.section {
	padding: 70px 0;
}

.title-1 {
	margin-bottom: 60px;

	font-size: 60px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--title-1);
	text-align: center;
}

.title-2 {
	margin-bottom: 20px;

	font-size: 40px;
	font-weight: 700;
	line-height: 1.3;
}


.footer {
	position: relative;
	z-index: 2;
	margin-top: auto;
	padding: 20px 0 15px;
	background-color: rgb(245, 245, 245);
	color: black;
    border-top: 1px solid var(--nav-border);
}

.footer__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 15px;
}

.social {
	display: flex;
	column-gap: 30px;
	align-items: center;
}

.copyright {
	font-size: 17px;
    justify-content: center;
}

.copyright p + p {
	margin-top: 0.5em;
}

.social__item {
	display: flex;
	justify-content: center;
    margin-right: 10px;
	padding-right: 11px;
    margin-top: -20px;
    margin-bottom: -40px;
	fill:black

}

