@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap");

:root {
	/* Colors */
	--blackpure: #000;
	--black: #171718;
	--black-border: #26292d;
	--white: #fff;
	--purple: #5c62ec;

	/* Nav */
	--nav-bg: var(--black);
	--nav-border: var(--black-border);
	--nav-text: var(--white);

	/* Header */
	--header-bg: var(--black);
	--header-text: var(--white);

	/* Footer */
	--footer-bg: var(--black);
	--footer-text: var(--white);

	/* Light mode */
	--page-bg: var(--white);
	--text-color: var(--black);
	--accent: var(--purple);
	--title-1: var(--accent);

	--project-card-bg: var(--white);
	--project-card-text: var(--black);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
}

.dark{
	/* Dark mode */
	--page-bg: #252526;
	--text-color: var(--white);

	--title-1: var(--white);

	--project-card-bg: var(--black);
	--project-card-text: var(--white);
	--box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8);
}

html,
body {
	min-height: 100vh;

	font-family: "DM Sans", sans-serif;
	letter-spacing: -0.5px;

	/* Light mode */
	background-color: var(--page-bg);
	color: var(--text-color);
}

body.dark header {
	background-color: #171718;
	color: var(--text-color-dark);
	/* меняй здесь */
}

body.dark nav {
	background-color: #171718;
}

body.dark .logo {
	color:white
}

body.dark .nav-list__link {
	color: rgb(255, 255, 255);
}

body.dark .dark-mode-btn {
	border-color: white;
}

body.dark footer {
	background-color: #171718;
	color: white;
}

body.dark .pie-chert {
	fill:#252526
}




#root {
	min-height: 100vh;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
/* General */

.container {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}

.none {
	display: none !important;
}

.section {
	padding: 70px 0;
}

.title-1 {
	margin-bottom: 60px;

	font-size: 60px;
	font-weight: 700;
	line-height: 1.3;
	color: var(--title-1);
	text-align: center;
}

.title-2 {
	margin-bottom: 20px;

	font-size: 40px;
	font-weight: 700;
	line-height: 1.3;
}

.main {
	padding: 40px 0;
	min-height: 695px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
}

input {
	width: 1000px;
	padding: 10px;
	border: 2px solid var(--black-border);
	border-radius: 20px;
	margin-right: 0;
	margin-left: 0;
	align-self: flex-start;
	position: relative;
	font-weight: bold;
}

.btn1 {
	cursor: pointer;
	position: absolute;
	margin-right: 3px;
	padding: 8px 10px;
	background-color: #5c62ec;
	border: none;
	border-radius: 60px;
	transition: background-color 0.5s ease;
}

.btn1:hover {
	background-color: #8184dd;
}


form {
	width: 1000px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-top: 0px;
	max-width: 1000px;
	margin-bottom: 40px;
}

@media (max-width: 1000px) {
	form {
		width: 95vw; /* Увеличиваем ширину формы до 95% от ширины вьюпорта */
		/* Другие стили */
	}
}

.diogram {
	max-width: 1000px;
	margin-right: auto;
	margin-top: 1000px;
}
